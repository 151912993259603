$primary: #bf862f;
$secondary: #1c3150;
$white: #fff;
$grey: #383838;
$darkGrey: #42484d;
$danger: #e02e2e;
$danger2: #d3465a;
$backgroundPrimary: #0e0e0e;
$backgroundSecondary: lighten($backgroundPrimary, 7);
// $backgroundTertiary: lighten($backgroundPrimary, 18);
$backgroundTertiary: #2e2e2e;

$font: 'Open Sans';

$textGrey: #9b9b9b;
$textWhite: #fff;

$asideWidth: 300px;
$mainWidth: 720px;

$editModalWidth: 760px;

.search {
	position: relative;
	& > .loader,
	& > svg {
		position: absolute;
		top: 17px;
		left: 20px;
		@include backface(16px);
	}
	& > svg {
		opacity: 1;
		pointer-events: none;
		transition: opacity .2s ease;
	}
	input {
		padding-left: 10px + 18px + 18px;
	}
	.search-addons {
		position: absolute;
		top: 11px;
		right: 11px;
		display: flex;
		*:not(:last-child) {
			margin: 0 9px 0 0;
		}
		button {
			position: relative;
			width: 28px;
			height: 28px;
			min-width: 28px;
			min-height: 28px;
			svg {
				fill: lighten($backgroundTertiary, 20) !important;
				position: static;
				width: 12px;
				height: 12px;
			}
		}
		.app-select--element {
			padding: 3.5px 8px;
			padding-right: 16px;
			&:not(:disabled) {
				cursor: pointer;
			}
		}
		.app-select--container svg {
			right: 8px;
		}
	}
	&.with-addon input {
		padding-right: 100px;
		text-overflow: ellipsis;
	}
	&.resetable.with-addon input {
		padding-right: 132px;
		text-overflow: ellipsis;
	}
}

.search-loading > svg {
	opacity: 0;
}

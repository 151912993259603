.app--title {
	// padding: 0 10px;
	display: inline-block;
	vertical-align: top;
	// border: 20px solid transparent;
	user-select: none;
	padding: 0 14px;
	background: lighten($backgroundSecondary, 4);
	// border-radius: 0;
	border-radius: 0;
	margin-bottom: calc(-28px/2);
	box-shadow: 0 1px 6px darken($backgroundSecondary, 1);
	z-index: 20;
	position: relative;
	// border-top-width: 0;
	// border-bottom-width: 30px;
	// border-bottom-color: $backgroundSecondary;
	p {
		// margin: 0 0 -30px;
		// padding: 10px 0 0;
		font-size: 14px;
		font-weight: 700;
		line-height: 28px;
		text-align: center;
		text-transform: uppercase;
	}
}

.app--wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	.app--wrapper-inner {
		padding: 32px;
		background: $backgroundSecondary;
		border-radius: 0;
		padding-top: calc( 32px + 28px/2);
	}
	@media screen and (max-width: 320px + 22px * 2) {
		.app--wrapper-inner {
			padding: 22px;
		}
	}
	.inner-head {
		margin: 0 0 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			fill: $primary;
		}
	}
}

.app--separator {
	display: flex;
	align-items: center;
	position: relative;
	&:before {
		content: '';
		width: 100%;
		display: block;
		position: absolute;
		z-index: 9;
		border-bottom: 1px solid rgba(#fff, .15);
	}
	p {
		margin: auto;
		z-index: 10;
		padding: 0 12px;
		position: relative;
		max-width: 80%;
		background: $backgroundSecondary;
		font-size: 11px;
		font-weight: 600;
		text-align: center;
		line-height: 15px;
		text-transform: uppercase;
	}
}

.app--icon-param {
	margin: 6px 0 0;
	display: flex;
	align-items: center;
	svg {
		fill: $primary;
		width: 12px;
		margin: 0 8px 0 0;
		font-size: 0;
		flex-shrink: 0;
	}
	p {
		color: $textGrey;
		font-size: 12.5px;
		font-weight: 600;
	}
}

.app--big-amount {
	color: #fff;
	padding: 22px 0;
	font-size: 34px;
	text-align: center;
}

.app--request-head {
	margin: 0 0 28px;
	display: flex;
	justify-content: center;
	.app--icon-param {
		margin: 0;
	}
}

.app--input-button {
	display: flex;
	align-items: flex-start;
	.app-square-icon {
		margin: 0 2px 0 0;
	}
	&.invalid {
		.app--input-button-element {
			border-color: $danger;
		}
	}
}

.app--input-button-element {
	display: flex;
	cursor: pointer;
	// border: 1px solid rgba(#fff, .15);
	min-width: 0;
	flex-grow: 1;
	justify-content: space-between;
	background: $backgroundTertiary;
	font-weight: 600;
	border-radius: 0;
	transition: background-color .2s ease, border-color .2s ease;
	button {
		flex-grow: 1;
		min-width: 0;
		cursor: pointer;
		padding: 15px 20px;
		display: flex;
		align-items: center;
	}
	p {
		font: 600 14px $font, Sans-serif;
		color: darken($textWhite, 8);
		flex-grow: 1;
		margin: 0 12px 0 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: left;
		user-select: none;
	}
	svg {
		width: 8px;
		flex-shrink: 0;
	}
}

.app--athlete-service {
	.service--label {
		display: flex;
		align-items: flex-end;
	}
	.service--type {
		color: $textGrey;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
	}
	.service--rate {
		color: darken($textWhite, 6);
		font-size: 24px;
		font-weight: 500;
	}
	.service--note {
		color: $textGrey;
		margin: 0 0 2px 6px;
		font-size: 12px;
		font-weight: 700;
	}
}

.app--athlete-service-inner {
	padding: 22px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.app--athlete-service-inner {
		padding: 0;
		display: block;
		.service--type {
			margin: 0 0 6px;
		}
	}
}

.app--athlete-service-appearances {
	padding: 0 22px 22px;
	.app--check:first-child {
		margin-top: 0;
	}
}

.app--athlete-service-locations {
	padding: 0 22px 22px;
	.app--map-location {
		padding: 20px;
	}
}

.app-legend .app--quantity-selector {
	padding: 20px 0;
}

.request--form {
	padding: 44px;
	&.type--endorsement-campaign {
		padding: 0;
		.form--inner {
			padding: 44px;
			@media screen and (max-width: 540px) {
				padding: 22px;
			}
			& > .app-input,
			.section > .app-input {
				margin: 0 0 20px;
			}
			.app-legend {
				margin: 0 0 24px;
			}
			& > .app-button {
				width: 100%;
			}
		}
		.message {
			color: $textGrey;
			margin: 0 auto 22px;
			font-size: 15px;
			max-width: 60%;
			font-weight: 600;
			text-align: center;
		}
	}
	& > p,
	.section > p {
		color: $textGrey;
		margin: 0 auto 22px;
		font-size: 15px;
		max-width: 60%;
		font-weight: 600;
		text-align: center;
	}
	.app--input-button {
		width: 100%;
		margin: 0 0 20px;
	}
	.app--date-picker {
		width: 100%;
		margin: 0 0 20px;
	}
	& > .app-input,
	.section > .app-input {
		margin: 0 0 20px;
	}
	.legend-section {
		padding: 22px;
		& > div:not(:last-child) {
			margin: 0 0 20px;
		}
	}
	& > .app-legend {
		margin: 0 0 24px;
	}
	& > .app-button {
		width: 100%;
	}
	.section {
		margin: 0 0 20px;
		.app-button {
			width: 100%;
		}
	}
	@media screen and (max-width: 1060px) {
		padding: 22px;
		.app--map-wrap {
			position: fixed;
			.map--search {
				padding-top: 55px + 22px;
			}
		}
	}
}

.page--profile {
	padding: 0;
}

.page--stats {
	.app-legend:not(:last-child) {
		margin: 0 0 28px;
	}
	& > .app-button {
		width: 100%;
	}
}

.page--athlete-profile {
	padding: 0;
	.page--container {
		padding: 32px;
		p.empty {
			border: 1px solid lighten($backgroundPrimary, 10);
			padding: 22px;
			text-align: center;
			background: lighten($backgroundPrimary, 4);
			border-radius: 0;
			box-shadow: 0 2px 6px rgba(darken($backgroundPrimary, 2), .2);
			font-size: 16px;
			font-weight: 600;
		}
	}
	// .app--tabs-head {
	// 	border-top: 1px solid lighten($backgroundPrimary, 10);
	// }
	.app-legend:not(:last-child) {
		margin: 0 0 28px;
	}
	.app--video-wrap:not(:last-child) {
		margin: 0 0 28px;
	}
	@media screen and (max-width: 1060px) {
		.page--container {
			padding: 22px;
		}
	}
}

.page--athlete-preview {
	display: flex;
	.preview-overlay {
		border-radius: 0;
		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(#000, .5);
	}
	.preview-container {
		margin: auto;
		position: relative;
		max-height: calc(100vh - (22px * 2) - 56px);
		flex-shrink: 1;
		.play-button {
			position: absolute;
			top: calc(50% - 76px/2);
			left: calc(50% - 76px/2);
			z-index: 10;
			margin: auto;
			cursor: pointer;
			padding: 18px;
			font-size: 0;
			background: rgba(#000, .2);
			border-radius: 0;
			pointer-events: auto;
			backdrop-filter: blur(10px);
			transition: background-color .2s ease, opacity .2s ease;
			svg {
				fill: $primary;
			}
			&:hover {
				background: rgba(#000, .4);
			}
			&.inactive {
				opacity: 0;
				pointer-events: none;
			}
		}
		video {
			width: 100%;
			height: 100%;
			opacity: 0;
			max-height: calc(100vh - (22px * 2) - 56px);
			background: $backgroundTertiary;
			box-shadow: 0 18px 24px rgba(darken($backgroundPrimary, 8), .2);
			object-fit: cover;
			transition: opacity .2s ease;
			border-radius: 0;
		}
		&.playing {
			video {
				opacity: 1;
			}
			.preview-info {
				opacity: 1;
				.content {
					transform: translateY(0);
				}
			}
		}
	}
	.preview-info {
		position: absolute;
		padding: 32px;
		padding-top: 64px;
		opacity: 0;
		bottom: 0;
		right: 0;
		left: 0;
		@media screen and (max-width: 413px) {
			padding: 22px;
			padding-top: 44px;
		}
		.content {
			z-index: 30;
			display: flex;
			position: relative;
			transform: translateY(10px);
			transition: transform .2s ease;
			align-items: flex-end;
			justify-content: space-between;
		}
		.overlay {
			position: absolute;
			z-index: 10;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 0;
			@include gradient(
				to bottom,
				hsla(0, 0%, 0%, 0) 0%,
				hsla(0, 0%, 0%, 0.01) 8.1%,
				hsla(0, 0%, 0%, 0.036) 15.5%,
				hsla(0, 0%, 0%, 0.078) 22.5%,
				hsla(0, 0%, 0%, 0.132) 29%,
				hsla(0, 0%, 0%, 0.194) 35.3%,
				hsla(0, 0%, 0%, 0.264) 41.2%,
				hsla(0, 0%, 0%, 0.338) 47.1%,
				hsla(0, 0%, 0%, 0.412) 52.9%,
				hsla(0, 0%, 0%, 0.486) 58.8%,
				hsla(0, 0%, 0%, 0.556) 64.7%,
				hsla(0, 0%, 0%, 0.618) 71%,
				hsla(0, 0%, 0%, 0.672) 77.5%,
				hsla(0, 0%, 0%, 0.714) 84.5%,
				hsla(0, 0%, 0%, 0.74) 91.9%,
				hsla(0, 0%, 0%, 0.75) 100%
			);
		}
		.user-content {
			display: flex;
			overflow: hidden;
			align-items: flex-end;
			img {
				width: 48px;
				height: 48px;
				display: block;
				background: $backgroundTertiary;
				box-shadow: 0 18px 24px rgba(darken($backgroundPrimary, 8), .2);
				object-fit: cover;
				border-radius: 0;
			}
			.name {
				margin: 0 22px;
				min-width: 0;
				@media screen and (max-width: 413px) {
					margin: 0 18px;
				}
				h4 {
					font-size: 18px;
					font-weight: 700;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.app--athlete-labels {
					margin: 2px 0 0;
					display: inline-flex;
					flex-wrap: wrap;
					align-items: flex-start;
					justify-content: flex-start;
					div {
						margin: 4.5px 14px 0 0;
						&:last-child {
							margin: 4.5px 0 0 0;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 414px) {
		padding: 0;
	}
}

.legend-container {
	padding: 22px;
	h3 {
		color: darken($textWhite, 6);
		margin: 0 0 16px;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.2;
		text-align: center;
	}
}

.legend-extender {
	padding: 22px;
	.app-button {
		width: 100%;
	}
}

.names--list {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	p {
		margin: 0 0 8px;
		flex-grow: .5;
		@include only-last(2) {
			margin: 0;
		}
	}
}

.app--review {
	margin: 0 0 28px;
	display: flex;
	align-items: flex-end;
	&:last-child {
		margin: 0;
	}
	img {
		width: 64px;
		height: 64px;
		border-radius: 0;
	}
	.review--content {
		margin: 0 0 0 12px;
		h4 {
			color: darken(#fff, 6);
			font-size: 16px;
			font-weight: 700;
		}
		.app--rating {
			margin: 6px 0;
		}
		p {
			color: $textGrey;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
}

.video--chat {
	padding: 0;
	.partner-preview {
		position: absolute;
		z-index: 90;
		top: -56px;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.user-preview {
		position: absolute;
		z-index: 91;
		top: 22px;
		right: 22px;
		width: 120px;
		height: 200px;
		& > div {
			overflow: hidden;
			box-shadow: 0 18px 24px rgba(darken($backgroundPrimary, 8), .2);
			background-color: $backgroundSecondary;
			border-radius: 0;
		}
		video {
			border-radius: 0;
		}
	}
	.leave-button {
		position: absolute;
		z-index: 91;
		bottom: 32px;
		left: 32px;
	}
}

.app--athlete-listed {
	display: flex;
	padding: 10px 20px;
	align-items: center;
	&:first-child { padding-top: 20px; }
	&:last-child { padding-bottom: 20px; }
	img {
		width: 50px;
		height: 50px;
		border-radius: 3px;
	}
	.col1,
	.col2 {
		flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		align-items: center;
		flex-direction: row;
	}
	.col2 {
		justify-content: flex-end;
	}
	.name {
		color: darken($textWhite, 6);
		margin: 0 8px;
		font-size: 18px;
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.app-square-icon {
		flex-shrink: 0;
		margin-left: 8px;
	}
	@media screen and (max-width: 560px) {
		flex-direction: column;
		.col1,
		.col2 {
			width: 100%;
		}
		.col2 {
			margin-top: 10px;
			justify-content: flex-start;
			.app-input {
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 375px) {
		.content {
			margin: 0 12px;
			p:first-child {
				font-size: 16px;
			}
		}
	}
}

.app--summary {
	padding: 22px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	p {
		flex-grow: 1;
		flex-shrink: 1;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	p:first-child {
		color: $textGrey;
		font-size: 15px;
		flex-shrink: 0;
		font-weight: 600;
		margin-right: 12px;
	}
	p:last-child {
		color: darken($textWhite, 6);
		font-size: 16px;
		font-weight: 600;
		text-align: right;
	}
}

a.link {
	color: $primary;
	font-weight: 500;
	text-decoration: underline;
}

.search.resetable {
	input {
		padding-right: 28px + 22px;
		text-overflow: ellipsis;
	}
}

.search-reset {
	position: absolute;
	top: 11px;
	right: 11px;
	button {
		position: relative;
		width: 28px;
		height: 28px;
		min-width: 28px;
		min-height: 28px;
		background-color: lighten($backgroundTertiary, 2);
		&.clickable:not(:disabled):hover {
			background-color: lighten($backgroundTertiary, 4);
		}
		svg {
			fill: #fff !important;
			position: static;
			width: 12px;
			height: 12px;
		}
	}
}

.profile-url-notice {
	color: $textGrey;
	margin: -10px 0 32px;
	padding: 0 0 0 65px;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	a {
		color: $primary;
		margin: 0 6px 0 0;
		font-weight: 700;
	}
	button {
		color: $primary;
		font-size: 12px;
		margin: 2px 0 0;
		padding: 0 4px;
		line-height: 20px;
		background: rgba($primary, .1);
		font-weight: 700;
		border-radius: 0;
		text-transform: uppercase;
		display: inline-block;
		align-items: center;
		cursor: pointer;
		transition: background-color .2s ease;
		&:hover {
			background: rgba($primary, .2);
		}
	}
}
